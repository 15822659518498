<template>
  <div class="work-card">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: ['openModal']
}
</script>
